import * as React from 'react';
import { v4 } from 'uuid';
import moment from 'moment';

import { IChatMessage, IChatMessageBare } from '../../../interfaces/IChat';

import Message from './Message';
import InputBar from './InputBar';
import InputBarAH from './InputBarAH';

export interface ChatProps {
  messages: IChatMessageBare[];
  enabled: boolean;
  connected: boolean;
  io: SocketIOClient.Socket;
  stream: string;
}

export interface ChatState {
  name?: string;
  messages: IChatMessage[];
  messageSent: boolean;
}

class Chat extends React.Component<ChatProps, ChatState> {
  private messagesRef = React.createRef<HTMLDivElement>();

  constructor(props: ChatProps) {
    super(props);

    this.state = {
      messages: [
        ...props.messages.map(
          (m) => ({ ...m, type: 'incoming' } as IChatMessage),
        ),
      ],
      messageSent: false,
    };
  }

  componentDidMount() {
    if (this.messagesRef.current)
      this.messagesRef.current.addEventListener('DOMNodeInserted', (_event) => {
        const target = document.getElementById('chat-wrapper');

        if (target)
          (target as HTMLDivElement).scroll({
            top: (target as HTMLDivElement)?.scrollHeight,
            behavior: 'smooth',
          });
      });

    const { io } = this.props;

    io.on('chatMessage', (message: IChatMessage) =>
      this.setState((s) => {
        const messages = s.messages.concat({ ...message, type: 'incoming' });

        return { messages };
      }),
    );

    const target = document.getElementById('chat-wrapper');

    if (target)
      (target as HTMLDivElement).scroll({
        top: (target as HTMLDivElement)?.scrollHeight,
        behavior: 'smooth',
      });
  }

  componentWillUnmount() {
    const { io } = this.props;

    io.off('chatMessage');
  }

  handleSetName = (name: string): void => this.setState({ name });

  handleSendMessage = (message: string): void => {
    const { io } = this.props;
    const { name = 'Onbekend' } = this.state;

    io.emit('chatMessage', { name, message });

    this.setState((s) => {
      const messages = [...s.messages];

      messages.push({
        id: v4(),
        date: moment().toISOString(),
        message,
        name,
        type: 'outgoing',
      });

      return { messages, messageSent: true };
    });
  };

  render() {
    const {
      enabled,
      connected,
      // stream
    } = this.props;
    const { name, messages, messageSent } = this.state;

    const isAHStream = false;
    // const isAHStream = stream === '0b54810c-3d8b-423c-8d67-5d698a46b71e';

    return (
      <>
        <div id="chat-wrapper">
          <div className="messages p-2" ref={this.messagesRef}>
            {messages.map((message) => (
              <Message message={message} key={message.id} />
            ))}
          </div>
        </div>
        {!connected && (
          <div className="notconnected-overlay">
            <span>
              <h2 className="h2">
                <i className="fas fa-unlink" />
              </h2>
              De verbinding is verbroken
              <br />
              We proberen de verbinding te herstellen
            </span>
          </div>
        )}
        {connected && !name && (
          <div className="name-overlay">
            <span>
              {isAHStream
                ? `Welkom! Voer je naam en vestiging in en chat met je collega's`
                : `Voer je naam in en druk op verbinden om mee te kunnen chatten`}
              <br />
              <span className="text-left">
                <h2 className="h2 mt-3">
                  <i className="fas fa-arrow-down" />
                </h2>
              </span>
            </span>
          </div>
        )}
        {name ? (
          <InputBar
            name="message"
            icon="paper-plane"
            placeholder={`${
              !messageSent && !isAHStream ? `Hoi ${name}. ` : ''
            }Typ hier je bericht`}
            handleSendText={this.handleSendMessage}
            disabled={!enabled}
          />
        ) : isAHStream ? (
          <InputBarAH
            name="name"
            buttonText="Verbinden"
            canPickEmoji={false}
            handleSendText={this.handleSetName}
            disabled={!enabled}
          />
        ) : (
          <InputBar
            name="name"
            buttonText="Verbinden"
            placeholder="Voer hier je naam in"
            canPickEmoji={false}
            handleSendText={this.handleSetName}
            disabled={!enabled}
          />
        )}
      </>
    );
  }
}

export default Chat;
