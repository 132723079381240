import * as React from 'react';
import CookieConsent from 'react-cookie-consent';
import { RouteComponentProps } from 'react-router-dom';
import io from 'socket.io-client';

import config from '../../config';

import { IChatMessage, IChatMessageBare } from '../../interfaces/IChat';
import { IQuizzzitRegistration } from '../../interfaces/IQuizzzit';

import Chat from '../components/chat';
import Player from '../components/player';

const {
  api: { endpoint },
} = config;

interface MatchParams {
  stream: string;
}

export type LiveWithChatProps = RouteComponentProps<MatchParams>;

export interface LiveWithChatState {
  connected: boolean;
  registered: boolean;
  source: string;
  chat?: IChatMessageBare[];
}

class LiveWithChat extends React.Component<
  LiveWithChatProps,
  LiveWithChatState
> {
  io: SocketIOClient.Socket = io(`${endpoint}`, {
    autoConnect: false,
  });

  constructor(props: LiveWithChatProps) {
    super(props);

    const {
      match: {
        params: { stream },
      },
      location: { search },
    } = props;

    const bunny =
      'https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8';

    const params = new URLSearchParams(search);
    const delay = params.get('delay');

    this.state = {
      connected: false,
      registered: false,
      source:
        stream === 'test'
          ? bunny
          : `https://quizzzit-${
              delay === 'medium' ? 'md' : 'ld'
            }.cdn.hostin.cc/ngrp:${stream}_live/playlist.m3u8`,
    };
  }

  componentDidMount() {
    this.io.open();
    this.io.on('connect', this.handleConnect);
    this.io.on('disconnect', this.handleDisconnect);
    this.registerIoEventHandlers();

    // setInterval(() => this.setState(({ chat }) => ({ chat: !chat })), 2000);
  }

  handleConnect = (): void => {
    const {
      match: {
        params: { stream },
      },
    } = this.props;

    this.io.emit('registerQuizzzit', stream);

    this.setState({ connected: true });
  };

  handleDisconnect = (): void => {
    this.setState({ connected: false, registered: false });
  };

  registerIoEventHandlers = (): void => {
    this.io.on('registered', ({ chat }: IQuizzzitRegistration) =>
      this.setState({ registered: true, chat }),
    );

    this.io.on('chat', (chat?: IChatMessage[]) => this.setState({ chat }));
  };

  render() {
    const { connected, registered, source, chat } = this.state;
    const {
      match: {
        params: { stream },
      },
    } = this.props;

    return (
      <>
        <CookieConsent
          buttonText="Ik snap het"
          cookieName="quzzzit_consent"
          location="bottom"
          sameSite="strict"
        >
          We plaatsen functionele cookies om deze website goed te laten werken.
          Er worden geen persoonsgegevens opgeslagen in deze cookies.
        </CookieConsent>
        <div className={`container${chat ? '-fluid ' : ''} h-100`}>
          <div className="row h-100">
            <div className="col my-auto">
              <div id="wrapper">
                <div id="player" className=" mt-5 mt-lg-0">
                  <Player className="w-100" source={source} />
                </div>
                {chat && (
                  <div id="chat" className="bg-primary mb-5 mb-lg-0">
                    <div className="bg-primary chat">
                      <Chat
                        io={this.io}
                        messages={chat}
                        connected={connected}
                        enabled={connected && registered}
                        stream={stream}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LiveWithChat;
