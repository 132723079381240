import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import 'moment/locale/nl';

import './styles/style.scss';

import 'jquery/dist/jquery';
import 'jquery-easing/dist/jquery.easing.1.3.umd';
import 'bootstrap/dist/js/bootstrap.min';

import History from './utilities/History';

import App from './App';

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <Router history={History}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
