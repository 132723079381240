import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Player from '../components/player';

import poster from '../../assets/images/poster_embed.png';

interface MatchParams {
  stream: string;
}

export type EmbedProps = RouteComponentProps<MatchParams>;

export interface EmbedState {
  source: string;
}

class Embed extends React.Component<EmbedProps, EmbedState> {
  constructor(props: EmbedProps) {
    super(props);

    const {
      match: {
        params: { stream },
      },
      location: { search },
    } = this.props;

    const params = new URLSearchParams(search);
    const delay = params.get('delay');

    let source = `https://quizzzit-${
      delay === 'medium' ? 'md' : 'ld'
    }.cdn.hostin.cc/ngrp:${stream}_live/playlist.m3u8`;

    if (stream === 'test-stream')
      source =
        'https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8';

    this.state = {
      source,
    };
  }

  render() {
    const { source } = this.state;

    return (
      <div className="embed">
        <Player source={source} nativeFullscreen poster={poster} />
      </div>
    );
  }
}

export default Embed;
