import * as React from 'react';
import moment from 'moment';

import { IChatMessage } from '../../../interfaces/IChat';

export interface MessageProps {
  message: IChatMessage;
}

const Message: React.FC<MessageProps> = ({
  message: { name, date, message, type },
}) => {
  return (
    <div className="message">
      <div className={type}>
        <div className="content">
          <p className="name">
            {name}{' '}
            <small className="font-italic">
              {moment(date).format('HH:mm')}
            </small>
          </p>
          {message}
        </div>
      </div>
    </div>
  );
};

export default Message;
