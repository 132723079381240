import * as React from 'react';

import Player from '../components/player';

const TestStream: React.FC = () => {
  return (
    <div className="container">
      <div className="h-100 row align-items-center">
        <div className="col">
          <Player
            className="w-100"
            source="https://quizzzit-k.cdn.hostin.cc/dummy.mp4"
            hls={false}
          />
        </div>
      </div>
    </div>
  );
};

export default TestStream;
