import * as React from 'react';
import Plyr, { FullScreenOptions } from 'plyr';
import Hls from 'hls.js';

import posterImage from '../../../assets/images/poster.png';

export interface PlayerProps {
  className?: string;
  style?: React.CSSProperties;
  source: string;
  hls?: boolean;
  nativeFullscreen?: boolean;
  poster?: string;
  updatePlayerHeight?: () => void;
}

export type PlayerState = Record<string, unknown>;

class Player extends React.Component<PlayerProps, PlayerState> {
  player: Plyr | undefined;

  componentDidMount() {
    const {
      source: src,
      hls: loadHls = true,
      nativeFullscreen = false,
    } = this.props;
    const video = document.querySelector('video');

    if (video) {
      let fullscreen: FullScreenOptions = {
        enabled: true,
        iosNative: false,
        fallback: 'force',
      };

      if (nativeFullscreen)
        fullscreen = {
          enabled: true,
          iosNative: true,
        };

      const options: Record<string, unknown> = {
        controls: [
          'play-large',
          'play',
          'mute',
          'volume',
          'settings',
          'fullscreen',
        ],
        settings: ['quality'],
        fullscreen,
      };

      if (loadHls && Hls.isSupported()) {
        const hls = new Hls();

        // Hls.js neemt het switchen van quality/level over. Zodra Plyr hierin
        // gaat deelnemen gaat het dus he-le-maal mis. Blijkbaar set Plyr direct
        // na het starten het level in de API van Hls.js waardoor deze nooit
        // meer op auto terecht komt.

        // const updateQuality = (quality: number) => {
        //   hls.levels.forEach((level, index) => {
        //     if (level.height === quality) hls.currentLevel = index;
        //   });
        // };

        hls.loadSource(src);

        hls.on(Hls.Events.MANIFEST_PARSED, (_event, _data) => {
          // const availableQualities = hls.levels.map(({ height }) => height);
          // //   .sort((a, b) => b - a);

          // console.log({ levels: hls.levels });

          // options.quality = {
          //   default: -1,
          //   options: availableQualities,
          //   forced: true,
          //   onChange: (e: number) => updateQuality(e),
          // };

          this.player = new Plyr(video, options);
          this.registerPlayerEventHandlers();
        });

        hls.attachMedia(video);
      } else {
        this.player = new Plyr(video, options);
        this.registerPlayerEventHandlers();

        this.player.source = {
          type: 'video',
          sources: [
            {
              src,
              provider: 'html5',
            },
          ],
        };
      }
    }
  }

  componentDidUpdate() {
    const { updatePlayerHeight } = this.props;

    if (updatePlayerHeight) updatePlayerHeight();
  }

  registerPlayerEventHandlers = () => {
    //
  };

  render() {
    const { className, style, poster } = this.props;

    return (
      <video
        className={className}
        style={style}
        preload="none"
        id="player"
        poster={poster ?? posterImage}
        crossOrigin="true"
        controls
        playsInline
      />
    );
  }
}

export default Player;
