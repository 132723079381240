import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import TestStream from './content/pages/TestStream';
import Embed from './content/pages/Embed';
import LiveWithChat from './content/pages/LiveWithChat';

const App: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <Switch>
        <Route path="/test-stream" component={TestStream} />
        <Route path="/embed/:stream" component={Embed} />
        <Route path="/live/:stream" component={LiveWithChat} />
        <Route
          render={() => {
            window.location.href = 'https://www.quizzzit.nl/';
            return null;
          }}
        />
      </Switch>
    </>
  );
};

export default App;
