import * as React from 'react';
import EmojiPicker, { IEmojiData } from 'emoji-picker-react';

export interface InputBarProps {
  name?: string;
  icon?: string;
  buttonText?: string;
  placeholder: string;
  canPickEmoji?: boolean;
  disabled?: boolean;
  handleSendText: (message: string) => void;
}

const InputBar: React.FC<InputBarProps> = ({
  name,
  icon,
  buttonText,
  placeholder,
  canPickEmoji = true,
  disabled,
  handleSendText,
}) => {
  const [text, setText] = React.useState('');
  const [emojiPicker, setEmojiPicker] = React.useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (text) {
      handleSendText(text.trim());
      setText('');
      setEmojiPicker(false);
    }
  };

  const handleEmojiClick = (_event: MouseEvent, { emoji }: IEmojiData) => {
    setText(`${text}${emoji}`);
  };

  return (
    <form className="input-bar d-flex" onSubmit={handleSubmit}>
      <input
        className="form-control flex-grow-1"
        type="text"
        name={name}
        placeholder={placeholder}
        value={text}
        onChange={({ currentTarget: { value } }) => setText(value)}
        readOnly={disabled}
      />
      {emojiPicker && (
        <div className="emoji-picker">
          <EmojiPicker disableAutoFocus onEmojiClick={handleEmojiClick} />
        </div>
      )}
      {canPickEmoji && (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => setEmojiPicker(!emojiPicker)}
          disabled={disabled}
        >
          <i className="fas fa-laugh-beam" />
        </button>
      )}
      <button
        type="submit"
        className="btn btn-warning"
        disabled={!text || disabled}
      >
        {icon && <i className={`fas fa-${icon}`} />}
        {buttonText && ` ${buttonText}`}
      </button>
    </form>
  );
};

export default InputBar;
